export const getMenu = async (timestamp = undefined) => {
  const items = await fetch(
    [
      `https://fwixs63gzqvjahbmn2t6ui5gqq0xxfnt.lambda-url.ap-northeast-2.on.aws`,
      timestamp && `?timestamp=${timestamp}`,
    ]
      .filter(Boolean)
      .join(''),
    {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
        Expires: '0',
      },
    }
  ).then((res) => res.json());
  return items;
};
