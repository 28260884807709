import React from 'react';
import { Link } from 'gatsby';
import { SEO } from '../components/SEO';
import { Textfit } from 'react-textfit';
import { StaticImage } from 'gatsby-plugin-image';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { Layout } from '../layouts/Layout';
import classNames from 'classnames';
import { LocationMapImage } from './location';
import { Video } from '../components/Video';
import { PhotoSlider } from '../components/PhotoSlider';
import { useWindowSize } from 'react-use';
import { getMenu } from '../utils/api';

const getDate = () => {
  const day = ['일', '월', '화', '수', '목', '금', '토'];
  const today = new Date();

  //1번 포맷
  const full =
    today.getFullYear() +
    '년 ' +
    (today.getMonth() + 1) +
    '월 ' +
    today.getDate() +
    '일 ' +
    day[today.getDay()] +
    '요일 ';

  //2번 포맷
  const short =
    today.getFullYear() +
    '-' +
    (today.getMonth() + 1 < 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1) +
    '-' +
    (today.getDate() < 9 ? '0' + today.getDate() : today.getDate());
  return { full, short };
};

const Button = ({ children, className = '', ...rest }) => (
  <button
    {...rest}
    className={classNames(
      'border-white border bg-transparent text-white bg-gray-200 bg-opacity-0 hover:bg-opacity-40 transition-colors w-[200px] py-2 mt-4',
      className
    )}
  >
    {children}
  </button>
);

const Section = ({ className = '', children, reduceHeaderSpace = false, id = undefined }) => {
  const { height } = useWindowSize();
  const [screenHeight, setScreenHeight] = React.useState(612);
  React.useEffect(() => {
    setScreenHeight((prev) => (prev < height ? height : prev));
  }, [height]);
  const sectionHeight = React.useMemo(
    () => (reduceHeaderSpace ? screenHeight - 50 : screenHeight),
    [screenHeight, reduceHeaderSpace]
  );
  return (
    <div
      id={id}
      className={classNames(
        'relative flex flex-col overflow-x-hidden items-center justify-center w-full p-4 m-auto text-white max-w-screen-sm mx-auto',
        // reduceHeaderSpace ? 'h-[calc(100vh-54.5px)]' : 'h-screen',
        className
      )}
      style={{ height: sectionHeight }}
    >
      {children}
    </div>
  );
};

export default function IndexPage(props) {
  // React.useEffect(() => {
  //   if (window.location.hash !== '#') {
  //     const target = document.querySelector(window.location.hash);
  //     if (target) {
  //       getScrollableParent(target).scrollTop = 0;
  //       requestIdleCallback?.(() => {
  //         requestAnimationFrame?.(() => {
  //           target.scrollIntoView({ block: 'center', behavior: 'smooth' });
  //         });
  //       });
  //     }
  //   }
  // }, []);
  const date = getDate();
  const [menu, setMenu] = React.useState<any>();
  React.useEffect(() => {
    if (date.short) {
      // fetch(`/api/menu?timestamp=${date.short}`)
      //   .then((res) => res.json())
      //   .catch(() => getMenu(date.short))
      getMenu(date.short)
        .then((data) => setMenu(data))
        .catch((error) => {
          console.error(error);
        });
    }
  }, [date.short]);
  console.log('🚀 ~ IndexPage ~ menu:', menu);
  return (
    <Layout className="relative bg-slate">
      <SEO title={null} titleTemplate="" />
      <Header noHeight className="sticky top-0 left-0 right-0 z-10" />
      <Section id="top" reduceHeaderSpace>
        <div className="absolute inset-0 opacity-70 z-[0]">
          <Video src={'/video/Main_1_Top.mp4'} />
        </div>
        <div className="absolute flex flex-col items-center justify-center -translate-x-1/2 bottom-4 left-1/2">
          Scroll Down
          <svg width="12" height="6" viewBox="0 0 12 6" xmlns="http://www.w3.org/2000/svg">
            <path
              d="m11.7 0 .3.286L6.014 6 6 5.987 5.986 6 0 .286.3 0 6 5.441 11.7 0z"
              fill="#FFF"
              stroke="currentColor"
              strokeWidth="1"
              fillRule="evenodd"
            />
          </svg>
        </div>
        <div className="z-[1] w-full p-4">
          <Textfit mode="single" className="w-full text-center">
            <span className="text-[.8em] block font-medium" style={{ lineHeight: '1em' }}>
              위례에서 가장 큰 규모
            </span>
            <span className="font-extrabold">위례센트럴 요양병원</span>
          </Textfit>
        </div>
      </Section>
      <Section id="doctors" reduceHeaderSpace className="min-h-[640px]">
        <div className="absolute inset-0 p-6 pt-16">
          <Textfit max={22} mode="multi" className="text-2xl" style={{ lineHeight: '1.8em' }}>
            <p>위례센트럴 요양병원은</p>
            <p>환자분들의 특성을 고려한</p>
            <p>
              전문화된 <big className="font-bold">요양,투석,재활,한방</big>
            </p>
            <p>의료 서비스를 제공합니다.</p>
          </Textfit>
          <Link to="/doctors">
            <Button>의료진 소개</Button>
          </Link>
        </div>
        <div className="absolute bottom-0 right-0 w-full max-w-[300px]">
          <StaticImage
            src="../resources/profile-kkm.png"
            className="w-full h-auto"
            objectFit="cover"
            objectPosition="center"
            placeholder="none"
            alt="hero"
            backgroundColor="transparent"
          />
        </div>
        {/* <div className="absolute bottom-0 right-4 w-full max-w-[144px]">
          <StaticImage
            src="../resources/logo_simple_square.png"
            className="w-full h-auto"
            objectFit="cover"
            objectPosition="center"
            placeholder="none"
            alt="hero"
            backgroundColor="transparent"
          />
        </div> */}
        <div className="absolute bottom-48 left-4 xs:left-8">
          <p className="text-sm font-thin">병원장</p>
          <h2 className="text-3xl font-extrabold">권 기 민</h2>
        </div>
      </Section>
      <Section id="rehab">
        <div className="absolute inset-0 opacity-100 z-[0]">
          <Video src={'/video/Main_2_jaehwal.mp4'} />
        </div>
        <div
          className="absolute inset-0 z-[1] py-16 px-6 flex flex-col justify-start items-center"
          style={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(58, 29, 0, 0))`,
          }}
        >
          <Textfit mode="single" max={28} className="w-full text-center">
            <span className="font-extrabold">재활 치료</span>
          </Textfit>
          <Textfit mode="multi" max={16} className="w-full my-2 text-center">
            <span className="font-thin break-words whitespace-pre-wrap">
              위례센트럴 요양병원은 환자 한 분 한 분을 위한 체계화된 맞춤식 전문재활치료를 제공합니다.
            </span>
          </Textfit>
          <Link to="/rehab">
            <Button>자세히 보기</Button>
          </Link>
        </div>
      </Section>
      <Section id="dialysis">
        <div className="absolute inset-0 opacity-100 z-[0]">
          <Video src={'/video/Main_3_tooseok.mp4'} />
        </div>
        <div
          className="absolute inset-0 z-[1] py-16 px-6 flex flex-col justify-start items-center"
          style={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(58, 29, 0, 0))`,
          }}
        >
          <Textfit mode="single" max={28} className="w-full text-center">
            <span className="font-extrabold">투석 치료</span>
          </Textfit>
          <Textfit mode="multi" max={16} className="w-full my-2 text-center">
            <span className="font-thin break-words whitespace-pre-wrap">
              위례센트럴 요양병원은 최신 Baxter사의 투석 기기를 사용하여 양질의 투석 치료를 제공합니다.
            </span>
          </Textfit>
          <Link to="/facilities">
            <Button>자세히 보기</Button>
          </Link>
        </div>
      </Section>
      <Section id="facilities">
        <div className="absolute inset-0 opacity-100 z-[0]">
          <Video src={'/video/Main_4_Room.mp4'} />
        </div>
        <div
          className="absolute inset-0 z-[1] py-16 px-6 flex flex-col justify-start items-center"
          style={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(58, 29, 0, 0))`,
          }}
        >
          <Textfit mode="single" max={28} className="w-full text-center">
            <span className="font-extrabold">쾌적한 병실</span>
          </Textfit>
          <Textfit mode="multi" max={16} className="w-full my-2 text-center">
            <span className="font-thin break-words whitespace-pre-wrap">
              모든 병상은 전동 침대, 병상 간 간격은 1.5m 이상 (신 병상 기준) 으로 쾌적한 입원실이며 신축의 장점인 깨끗한
              공조시스템이 있습니다.
            </span>
          </Textfit>
          <Link to="/facilities">
            <Button>자세히 보기</Button>
          </Link>
        </div>
      </Section>
      <div
        id="photos"
        className="flex flex-col items-center justify-start w-full h-auto py-12"
        style={{
          background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(58, 29, 0, 0))`,
        }}
      >
        <Textfit mode="single" max={28} className="w-full mx-6 text-center text-white">
          <span className="font-extrabold">병원 둘러보기</span>
        </Textfit>
        {/* <Textfit mode="multi" max={16} className="w-full my-2 text-center">
            <span className="font-thin break-words whitespace-pre-wrap">
              모든 병상은 전동 침대, 병상 간 간격은 1.5m 이상 (신 병상 기준) 으로 쾌적한 입원실이며 신축의 장점인 깨끗한
              공조시스템이 있습니다.
            </span>
          </Textfit> */}
        <div className="w-full h-auto py-6">
          <PhotoSlider className="" />
        </div>
      </div>
      {menu?.items[0] && (
        <div
          id="menu"
          className="flex flex-col items-center justify-start w-full h-auto py-12"
          style={{
            background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(58, 29, 0, 0))`,
          }}
        >
          <Textfit mode="single" max={28} className="w-full mx-6 text-center text-white">
            <span className="font-extrabold">식단표</span>
          </Textfit>
          <Textfit mode="multi" max={16} className="w-full my-2 text-center text-white">
            <span className="font-thin break-words whitespace-pre-wrap">{date.full}</span>
          </Textfit>
          <div className="w-full h-auto py-6 text-center text-white">
            <span className="block my-4 text-lg font-bold break-words whitespace-pre-wrap">조식</span>
            <span className="font-thin break-words whitespace-pre-wrap">{menu?.items[0][1]}</span>
            <span className="block my-4 text-lg font-bold break-words whitespace-pre-wrap">중식</span>
            <span className="font-thin break-words whitespace-pre-wrap">{menu?.items[0][2]}</span>
            <span className="block my-4 text-lg font-bold break-words whitespace-pre-wrap">석식</span>
            <span className="font-thin break-words whitespace-pre-wrap">{menu?.items[0][3]}</span>
          </div>
        </div>
      )}
      {/* <Link to="/location"> */}
      <a href="http://naver.me/GttYJixf" target="_blank">
        <LocationMapImage />
      </a>
      {/* </Link> */}
      <Footer />
    </Layout>
  );
}
