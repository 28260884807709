import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Zoom, Autoplay } from 'swiper';
import { StaticImage } from 'gatsby-plugin-image';

// import styles bundle
// import 'swiper/css';
// import 'swiper/css/bundle';

const images = [
  <StaticImage
    src="../resources/W00.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W01.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W02.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W03.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W04.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W05.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W06.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W07.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W08.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
  <StaticImage
    src="../resources/W09.jpg"
    className="w-full h-auto"
    objectFit="cover"
    objectPosition="center"
    placeholder="none"
    alt="photo"
    backgroundColor="transparent"
  />,
];

export const PhotoSlider = ({ className }) => (
  <>
    <link rel="stylesheet" href="https://unpkg.com/swiper@8/swiper-bundle.min.css" />

    <Swiper
      spaceBetween={10}
      slidesPerView={1.1}
      grabCursor
      autoplay={{ pauseOnMouseEnter: false, delay: 3000 }}
      centeredSlides
      pagination={{ clickable: true }}
      modules={[Pagination, Autoplay, Zoom]}
      className={className}
    >
      {images.map((image, idx) => (
        <SwiperSlide key={idx} zoom className="overflow-hidden rounded-md shadow-sm">
          {image}
        </SwiperSlide>
      ))}
    </Swiper>
  </>
);
